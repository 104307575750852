import React, { useRef, useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CHeroSlider03,
  CShopInfo,
  CGrandMenu,
  CShopPrivate,
  CRestaurantPlanRecommend,
  CRestaurantJumbotron,
  CBtnList,
  LContact02,
  LContact03,
  LStores,
  CVariableSlider,
} from '../../../components/_index';
import shopGet from '../../../utils/shop-get';
import restaurantPlanChoice from '../../../utils/restaurantPlan-choice';
import restaurantPlanGet from '../../../utils/restaurantPlan-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const shopdata = shopGet('sumida');
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CRestaurantJumbotron
        data={{
          logo: {
            src: '/assets/images/restaurants/sumida/sumida_logo.svg',
            alt: '',
            widthSp: '160px',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/sumida/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/sumida/kv__sp.png',
              },
            },
          ],
        }}
      ></CRestaurantJumbotron>
      <LContact02
        btn={{
          btn: {
            label: '予約する',
            link: {
              href: 'https://www.tablecheck.com/ja/shops/royalparkhotel-tokyo-sumida/reserve',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
      />
      <div className="u_pt100 u_mbLarge" style={{ overflow: 'hidden' }}>
        <LWrap>
          <CHeroSlider03
            exClass="slick-aspect-1"
            data={{
              imgList: [
                {
                  img: {
                    src: '/assets/images/restaurants/sumida/img_sumida.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/sumida/img_sumida__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/sumida/img_sumida02.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/sumida/img_sumida02__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/sumida/img_sumida03.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/sumida/img_sumida03__sp.png',
                    alt: '',
                  },
                },
              ],
              title: <>ホテル最上階、目の前で調理される魚介や肉に舌鼓</>,
              text: (
                <>
                  東京の景色を眼下に臨む素晴らしい眺めの中、吟味された和牛や魚介類を、
                  <br />
                  熟練したシェフの鮮やかな手さばきとともに。ライブ感溢れる鉄板焼を五感でお楽しみください。
                </>
              ),
            }}
          />
          <CHeroSlider03
            exClass="slick-aspect-1 u_mb100"
            reverse={true}
            data={{
              imgList: [
                {
                  img: {
                    src: '/assets/images/restaurants/sumida/img_sumida04.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/sumida/img_sumida04__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/sumida/img_sumida05.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/sumida/img_sumida05__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/sumida/img_sumida06.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/sumida/img_sumida06__sp.png',
                    alt: '',
                  },
                },
              ],
              text: (
                <>
                  お客様の目の前で調理し、サービスされる趣向を凝らしたコース料理も季節ごとにご用意。
                  普段の会食はもちろん、結婚記念日やお誕生日、ご接待やご家族との会食などさまざまなシーンでご利用いただけます。
                </>
              ),
            }}
          />
          <CSectTitle
            title={{
              en: 'INFORMATION',
              ja: '店舗情報',
            }}
          />
          <p className="u_tac_sp">
            最新の営業時間は、<a href="/information/timeindex/">こちら</a>
            をご覧ください。
          </p>
          <CShopInfo data={shopdata} exClass="u_mt20" />
        </LWrap>
      </div>
      <section className="l_sect u_bgGray" style={{ overflow: 'hidden' }}>
        <LWrap>
          <CSectTitle
            title={{
              en: 'MENU',
              ja: 'メニュー',
            }}
          />
          <CRestaurantPlanRecommend
            data={restaurantPlanChoice(
              restaurantPlanGet(),
              ['鉄板焼 すみだ'],
              0
            )}
          />
          <CBtnList
            data={[
              {
                label: '他のプランを見る',
                link: {
                  href: '/restaurants/plan/',
                },
                color: 'bgWhite03',
              },
            ]}
          />
          <CGrandMenu data={shopdata} exClass="u_mt70 u_mb20" />
          <ul className="c_noteList u_mbMedium">
            <li>季節により内容が変わります。</li>
          </ul>
        </LWrap>
      </section>
      <section className="l_sect u_pb40_sp">
        <LWrap>
          <CSectTitle
            title={{
              en: 'PRIVATE ROOM',
              ja: '個室',
            }}
          />
          <CVariableSlider
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/sumida/img_sumida08.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/sumida/img_sumida09.png',
                  alt: '',
                },
              },
            ]}
          />
          <CShopPrivate
            data={shopdata}
            btn={{
              label: 'レストラン個室のご案内',
              link: {
                href: '/restaurants/private/',
              },
            }}
          />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'レストラン&バー',
              path: '/restaurants/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        btn={{
          btn: {
            label: '予約する',
            link: {
              href: 'https://www.tablecheck.com/ja/shops/royalparkhotel-tokyo-sumida/reserve',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
        linkList={[
          {
            label: 'よくあるご質問',
            link: {
              href: '/faq/restaurants/',
            },
          },
          {
            label: '食物アレルギーをお持ちの方へ',
            link: {
              href: '/restaurants/allergy/',
            },
          },
        ]}
      />
      <LStores />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
